import React, { useEffect } from "react";

// markup
const GoToAppPage = (props) => {
	const params = new URLSearchParams(props.location.search);

	useEffect(() => {
		window.location.replace(params.get('redirect'));  
	}, []);

	return (
		<></>
	);
};

export default GoToAppPage;
